import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

// DOC: https://getbootstrap.com/docs/5.3/getting-started/introduction/
import 'bootstrap'; // Importa Bootstrap JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './assets/css/default.css';

// DOC: https://github.com/sagalbot/vue-select
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";

if (process.env.NODE_ENV === 'production') {
  // Deshabilitar DevTools en producción
  import('vue').then((Vue) => {
    Vue.config.devtools = false;
  });
}

createApp(App)
  .component('v-select', vSelect)
  .use(createPinia()) // Habilita Pinia en la app
  .mount('#app')
