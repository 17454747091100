import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    host: "https://apk.donicus.com/app.services.freetour", 
    //host: "https://localhost:5780",
    accessToken: null,
  }),

  actions: {
    async login(username, password) {
      try {
        const response = await axios.post(`${this.host}/token`, 
          new URLSearchParams({
            grant_type: 'password',
            username,
            password
          }), 
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
          }
        );

        this.accessToken = response.data.access_token;
        localStorage.setItem('access_token', this.accessToken);
        return { success: true, message: "Login successful" };
      } catch (error) {
        console.error("Login error:", error.response?.data || error.message);
        return { success: false, message: error.response?.data?.error || "Error logging in" };
      }
    },

    logout() {
      this.accessToken = null;
      localStorage.removeItem('access_token');
    },

    loadToken() {
      this.accessToken = localStorage.getItem('access_token');
    }
  },
});
