<template>
  <div
    class="user-profile d-flex justify-content-between align-items-center p-3 border-bottom"
  >
    <div class="user-info d-flex align-items-center">
      <img
        :src="require('@/assets/img/avatar.svg')"
        alt="User Photo"
        class="profile-img rounded-circle me-3"
      />

      <div>
        <h5 class="mb-0 text-white">{{ userData.name }}</h5>
      </div>
    </div>

    <!-- Botón de Logout con icono -->
    <button
      @click="logout"
      class="btn btn-outline-light d-flex align-items-center"
      title="logout"
    >
      <i class="bi bi-box-arrow-right"></i>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore"; 
import axios from "axios";

const authStore = useAuthStore();
const userData = ref({
  name: "",
});

// Obtener los datos del usuario al montar el componente (esto puede provenir de tu store o de un API)
onMounted(async () => {
  // Aquí puedes cargar datos de usuario, por ejemplo, desde un API
  if (authStore.accessToken) {
    //TODO: Obtener los datos del usuario
    try {
      const response = await axios.get(`${authStore.host}/user`, {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      });
      userData.value = response.data;      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
});

// Método para hacer logout
const logout = () => {
  authStore.logout(); // Esto limpiará el accessToken de tu store
};
</script>

<style scoped>
.user-profile {
  background-color: var(--donPrimary);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

button {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #e6e6e6;
}
</style>
