<template>
  <div class="mt-2 p-4">
    <user-profile class="mb-4"></user-profile>
    <!-- Filtro Panel -->
    <div class="row mb-2">
      <div class="col-md-3 mb-2">
        <v-select :options="countriesData" placeholder="[Search by country]" label="title" :reduce="(x) => x.id"
          v-model="filters.countryId" @option:selected="getCitiesData()" :clearable="false"
          :filter="customFilter"></v-select>
      </div>
      <div class="col-md-3 mb-2">
        <v-select :options="citiesData" label="title" :reduce="(x) => x.id" v-model="filters.cityId"
          placeholder="[Search by city]" :clearable="false" @option:selected="
            cleanup();
          getActivities();
          " :filter="customFilter"></v-select>
      </div>
      <div class="col-md-3 mb-2" v-if="data.length">
        <input v-model="filters.activity" type="text" class="form-control" placeholder="Search by activity" />
      </div>
      <div class="col-md-3 mb-2" v-if="data.length">
        <div class="d-flex align-items-center">
          <label class="icon-label me-2" @click="filters.createdYoorney = !filters.createdYoorney">
            <i v-if="filters.createdYoorney" class="bi bi-check2-square text-yellow"></i>
            <i v-else class="bi bi-app text-yellow"></i>
          </label>
          <span>Created in Yoorney</span>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12 mb-2">
        <button class="btn btn-outline-success d-flex align-items-center" type="button" @click=" noMoreData = false; lastPage = false; page = 1;refresh()"
          :disabled="!filters.countryId || !filters.cityId">
          <i class="bi bi-search me-2"></i> Search
        </button>
      </div>
    </div>

    <div v-if="(hasSelected || isOpenActions) && !isActualizando"
      class="d-flex justify-content-start align-items-center mb-2 border rounded bg-success text-white ps-3 pannel-actions">
      <div class="d-flex align-items-center me-4" v-if="hasItems && !isActualizando" @click="selectedAll">
        <span class="me-2">{{
          hasSelected == data.length ? "Deselect" : "Select"
        }}
          all ({{
            hasSelected == data.length
              ? hasSelected
              : hasSelected + "/" + data.length
          }})</span>
        <label class="custon-icon-label">
          <i v-if="hasSelected == data.length" class="bi bi-check2-square text-yellow"></i>
          <i v-else class="bi bi-app text-yellow"></i>
        </label>
      </div>

      <button class="btn btn-outline-link d-flex align-items-center text-white" type="button" style="font-size: 14px"
        @click="openModal()" v-if="hasSelected && !isActualizando">
        <i class="bi bi-arrow-clockwise text-yellow custom-icon me-2"></i>
        Update activities ({{ hasSelected }})
      </button>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="activityModal" tabindex="-1" aria-labelledby="activityModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="activityModalLabel">
              Update activities ({{ hasSelected }})
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="alert alert-warning text-center d-flex justify-content-center align-items-center flex-column"
              role="alert">
              <p>
                Are you about to update the following selected activities?
                Press "Update" to continue...
              </p>
              <button type="button" class="btn btn-primary" @click="actualizarActividades" v-if="!isActualizando">
                Update
              </button>
              <template v-else>
                <p>Updating...</p>
                <button type="button" class="btn btn-secondary" @click="isCancel = true">
                  Cancel and close
                </button>
              </template>
            </div>

            <div class="table-responsive">
              <table class="table table-sm table-bordered table-striped align-middle">
                <thead class="table-warning">
                  <tr>
                    <th class="text-center w-5"></th>
                    <th>Activity</th>
                    <th class="w-30">Provider</th>
                    <th class="text-center w-20" style="min-width: 200px">
                      UpdateAt
                    </th>
                    <th class="text-center w-5">Link</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data.filter((x) => x.checked)" :key="index">
                    <td>
                      <div class="spinner-border spinner-border-sm text-yellow" role="status" v-if="item.updating">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <i v-else class="bi bi-stop-circle text-warning"></i>
                    </td>
                    <td style="min-width: 300px">
                      <div class="d-flex align-items-center">
                        <img :src="item.image" class="tour-img-read shadow-sm rounded-3 me-3" />
                        <span>{{ item.title }} ({{ item.id }})</span>
                      </div>
                    </td>
                    <td>{{ item.providerTitle }}</td>
                    <td class="text-center">{{ item.updatedAt }}</td>
                    <td class="text-center">
                      <a :href="item.url" target="_blank" :title="item.url"><i class="bi bi-box-arrow-up-right"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-if="!isActualizando">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Results Table  -->
    <div class="table-responsive">
      <table class="table table-sm table-bordered table-striped align-middle">
        <thead class="table-warning">
          <tr>
            <th class="text-center w-5">
              <i class="bi bi-list cursor-pointer" v-if="!isOpenActions"
                @click="hasItems ? (isOpenActions = true) : false"></i><i v-else class="bi bi-x cursor-pointer"
                @click="!hasSelected ? (isOpenActions = false) : false"></i>
            </th>
            <th style="min-width: 300px">Activity</th>
            <th class="w-30">Provider</th>
            <th class="text-center w-20" style="min-width: 200px">UpdateAt</th>
            <th class="text-center w-5">Link</th>
            <th class="text-center w-5">Links <sub>Yoorney</sub></th>
            <th class="text-center w-5">Created <sub>Yoorney</sub></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in dataSource" :key="index" :class="[{ 'table-active': item.checked }]">
            <td class="text-center">
              <button class="btn btn-xs btn-danger" v-if="item.existsYoorneyNotIsFreetourCom && !item.overwrite"
                @click="item.overwrite = true; item.checked = true;">Overwrite</button>
              <label v-else
                @click="item.checked = !item.checked; item.overwrite = !(!item.checked && item.existsYoorneyNotIsFreetourCom)"
                class="icon-label">
                <i v-if="item.checked" class="bi bi-check2-square text-white"></i>
                <i v-else class="bi bi-app text-yellow"></i>
              </label>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <img :src="item.image" class="tour-img shadow-sm rounded-3 me-3" />
                <span>{{ item.title }} ({{ item.id }})</span>
              </div>
              <span v-if="item.error" class="error-message">⚠️ {{ item.error }}</span>
            </td>
            <td>{{ item.providerTitle }}</td>
            <td class="text-center">{{ item.updatedAt }}</td>
            <td class="text-center">
              <a :href="item.url" target="_blank" :title="item.url"><i class="bi bi-box-arrow-up-right"></i></a>
            </td>
            <td class="text-center">
              <a v-for="flag in item.linkYoorney" :key="flag.lang" :href="flag.url" target="_blank">
                <img class="flag shadow-sm rounded-1 me-2" :src="require(`@/assets/img/flags/${flag.lan}.svg`)"
                  :alt="item.linkYoorney.url" />
              </a>
            </td>
            <td class="text-center">{{ item.createdYoorney }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="loading" class="text-center mt-3">
        <i class="spinner-border spinner-border-sm text-yellow" role="status"></i>
        <span> Loading...</span>
      </div>
      <div v-if="noMoreData" class="text-center mt-3">
        <span>No more data available.</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import UserProfile from "@/components/DonUserProfile.vue";
import { useAuthStore } from "@/stores/authStore";

import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import axios from "axios";
import { Modal } from "bootstrap";

const authStore = useAuthStore();

// Reactive state variables
const filters = ref({
  activity: "",
  countryId: null,
  cityId: null,
  createdYoorney: false,
});
const data = ref([]);
const page = ref(1);
const loading = ref(false);
const noMoreData = ref(false);
const lastPage = ref(false);
const countriesData = ref([]);
const citiesData = ref([]);
const isActualizando = ref(false);
const isCancel = ref(false);
const isOpenActions = ref(false);

const openModal = () => {
  const modal = new Modal(document.getElementById("activityModal"), {
    backdrop: "static", // No se cierra al hacer clic fuera
    keyboard: false, // No se cierra con la tecla Esc
  });
  modal.show();
};

const hasSelected = computed(() => {
  return data.value.filter((item) => item.checked).length;
});

const hasItems = computed(() => {
  return data.value.length;
});

const dataSource = computed(() => {
  return data.value.filter((item) => {
    return (
      normalizeString(`${item.title} (${item.id})`)
        .toLowerCase()
        .includes(normalizeString(filters.value.activity).toLowerCase()) &&
      (filters.value.createdYoorney ? item.createdYoorney : true)
    );
  });
});

function normalizeString(str) {
  if (str === null || str === undefined) return "";
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const customFilter = (options, search) => {
  // Normalizar tanto la opción como el término de búsqueda
  const normalizedSearch = normalizeString(search).toLowerCase();

  return options.filter((option) => {
    return normalizeString(option.title)
      .toLowerCase()
      .includes(normalizedSearch);
  });
};

const selectedAll = () => {
  const selected =
    data.value.filter((x) => x.checked).length != data.value.length;
  data.value.forEach((x) => {
    x.checked = selected;
    x.overwrite = !(!selected && x.existsYoorneyNotIsFreetourCom);
  });
};

const actualizarActividades = async () => {
  isActualizando.value = true;

  data.value.filter(x => x.error).forEach(x => {
    x.error = null;
  });

  const selectedData = data.value.filter((x) => x.checked);

  for (const x of selectedData) {
    if (isCancel.value) {
      isActualizando.value = false;
      isCancel.value = false;
      break;
    }

    try {
      x.updating = true;
      const response = await axios.post(
        `${authStore.host}/tour/save/${x.id}/${x.overwrite}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authStore.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        x.checked = false;
      }
      x.updating = false;
    } catch (error) {
      console.error("Error actualizarActividades data:", error);
      isActualizando.value = false;
      x.error = `Error updating activity: ${error.message} (${error.response.data.errors})`;
      x.checked = false;
      x.overwrite = !(!x.checked && x.existsYoorneyNotIsFreetourCom);
      //return; // Detenemos la ejecución en caso de error
    }
  }

  isActualizando.value = false;

  if (!data.value.some((x) => x.checked)) {
    // Cerrar el modal después de completar todas las solicitudes
    const modal = Modal.getInstance(document.getElementById("activityModal"));
    if (modal) {
      modal.hide();
    }
    // Si no existen errores actualizamos la tabla
    if (!data.value.some(x => x.error)) {
      refresh();
    }
  }
};

const refresh = () => {
  data.value = [];
  for (let i = 1; i <= page.value; i++) {
    getActivities(i);
  }
};

// Function to fetch data from API
const getActivities = async (pageIn = 0) => {
  if (
    loading.value ||
    lastPage.value ||
    filters.value.countryId == null ||
    filters.value.cityId == null
  )
    return; // Don't fetch if already loading or no more data

  loading.value = true;
  try {
    const response = await axios.post(
      `${authStore.host}/tour`,
      {
        //activity: filters.value.activity,
        countryId: filters.value.countryId,
        cityId: filters.value.cityId,
        page: pageIn == 0 ? page.value : pageIn,
      },
      {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      }
    );

    if (response.data.data.length === 0) {
      noMoreData.value = true;
      lastPage.value = true;
    } else {
      const newData = response.data.data.map((item) => ({
        ...item,
        checked: false,
        updating: false,
        error: null
      }));

      data.value = [...data.value, ...newData];
      page.value += 1;
    }
  } catch (error) {
    console.error("Error getActivities data:", error);
  } finally {
    loading.value = false;
  }
};

// Function to handle scroll event
const handleScroll = () => {
  const scrollThreshold = 5;
  const atBottom =
    document.documentElement.scrollHeight - window.scrollY - window.innerHeight <= scrollThreshold;
  if (atBottom && !noMoreData.value && !loading.value) {
    getActivities();
  }
};

// Lifecycle hooks
onMounted(() => {
  getCountriesData();
  window.addEventListener("scroll", handleScroll);
});

const getCountriesData = async () => {
  try {
    const response = await axios.get(`${authStore.host}/tour/countries`, {
      headers: {
        Authorization: `Bearer ${authStore.accessToken}`,
      },
    });
    if (response.data.status == "200") {
      countriesData.value = response.data.data;
    } else {
      countriesData.value = [];
    }
  } catch (error) {
    console.error("Error getCountries data:", error);
  }
};

const getCitiesData = async () => {
  try {
    const response = await axios.get(
      `${authStore.host}/tour/cities/${filters.value.countryId}`,
      {
        headers: {
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      }
    );
    filters.value.cityId = null;
    cleanup();
    if (response.data.status == "200") {
      citiesData.value = response.data.data;
    } else {
      citiesData.value = [];
    }
  } catch (error) {
    console.error("Error getCities data:", error);
  }
};

// Cleanup
const cleanup = () => {
  data.value = [];
  filters.value.activity = "";
  filters.value.createdYoorney = false;
  noMoreData.value = false;
  lastPage.value = false;
  page.value = 1;
};

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll); // Clean up the event listener when the component is destroyed
});
</script>

<style scoped>
.pannel-actions {
  font-size: 14px;
  position: sticky;
  top: 0px;
  background: white;
  padding: 8px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 20;
  display: none;
}

.error-message {
  display: block;
  margin-top: 5px;
  padding: 8px 12px;
  background-color: rgba(255, 0, 0, 0.1);
  /* Fondo rojo suave */
  color: #d9534f;
  /* Rojo de Bootstrap */
  border-left: 4px solid #d9534f;
  /* Borde rojo lateral */
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.flag {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.custom-icon {
  font-size: 18px;
  line-height: 0px;
}

.custon-icon-label {
  cursor: pointer;
  font-size: 18px;
}

.tour-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.tour-img-read {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.icon-label {
  cursor: pointer;
  font-size: 25px;
}

.table-active td {
  background-color: #fec107 !important;
  outline: none !important;
  box-shadow: none !important;
}
</style>
