<template>
  <activity v-if="hasAccessToken"></activity>
  <login v-else></login>
</template>

<script setup>
import { onMounted, computed } from 'vue'
import { useAuthStore } from '@/stores/authStore' // Importa tu store
import login from './components/DonLogin.vue'
import activity from './views/Activity/DonIndex.vue'

// Obtener el store de autenticación
const authStore = useAuthStore()

// Cargar el token cuando se monta el componente
onMounted(() => {
  authStore.loadToken() // Cargar el token del localStorage al iniciar
})

// Verificar si existe el accessToken
const hasAccessToken = computed(() => !!authStore.accessToken)
</script>


