<template>
  <div class="container d-flex justify-content-center align-items-center min-vh-100">
    <div class="card p-4 shadow-lg" style="width: 350px;">
      <h2 class="text-center text-yellow p-4">donicus</h2>

      <form @submit.prevent="handleLogin">
        <!-- Campo de Correo -->
        <div class="mb-3">
          <label for="email" class="form-label fw-bold">Email</label>
          <input v-model="email" type="email" class="form-control" required />
        </div>

        <!-- Campo de Contraseña con botón para mostrar/ocultar -->
        <div class="mb-3">
          <label for="password" class="form-label fw-bold">Password</label>
          <div class="input-group">
            <input
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              required
            />
            <button
              type="button"
              class="btn btn-outline-secondary"
              @click="showPassword = !showPassword"
            >
              <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
            </button>
          </div>
        </div>

        <button type="submit" class="btn btn-success w-100 mt-3" :disabled="loading">
          {{ loading ? "Loading..." : "Login" }}
        </button>

        <p v-if="errorMessage" class="text-danger text-center mt-2">{{ errorMessage }}</p>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';

const email = ref('');
const password = ref('');
const showPassword = ref(false); // Maneja la visibilidad de la contraseña
const authStore = useAuthStore();
const loading = ref(false);
const errorMessage = ref('');

const handleLogin = async () => {
  loading.value = true;
  errorMessage.value = '';

  const result = await authStore.login(email.value, password.value);

  if (!result.success) {
    errorMessage.value = result.message;
  }

  loading.value = false;
};
</script>
